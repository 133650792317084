<script lang="ts" setup>
import {RefugeImage} from "~/types/RefugeImage";
import {ref} from "vue";

const stockImages = ref([
  new RefugeImage("https://res.cloudinary.com/dlct0dnwt/image/upload/v1684618504/refuge/stock/sleep_nidfpg.jpg", "Interior, beds"),
  new RefugeImage("https://res.cloudinary.com/dlct0dnwt/image/upload/v1684618456/refuge/stock/food_jprilv.jpg", "Food on table"),
  new RefugeImage("https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614558/refuge/stock/skier_czfpiy.jpg", "Skier"),
])
</script>

<template>
  <div>

    <!-- Floating ESPA logo   -->
    <a href="https://res.cloudinary.com/dlct0dnwt/image/upload/v1687110089/refuge/espa-tourismos_pfhs10.pdf"
       target="_blank">
      <CldImage
          src="refuge/espa_logo_cyvem7"
          alt="ESPA logo" class="espa"
          width="400px"
          height="400px"
          loading="lazy"
          quality="40"
      />
    </a>

    <div class="home-container">
      <div class="banner">
        <video autoplay muted loop playsinline width="100%">
          <source
              src="https://res.cloudinary.com/dlct0dnwt/video/upload/w_1920/q_auto:eco/v1707660416/refuge/videos/refuge_promotion_rvvdsg.mp4"
              type="video/mp4">
        </video>

        <h1 class="banner-text">{{ $t('welcomeMessage') }}</h1>
      </div>
      <div class="main-content">
        <weather-widget></weather-widget>

        <div class="section flex-column">
          <h2> {{ $t('launchPageHeader') }}</h2>
          <h3> {{ $t('heartOfTzoumerka') }}</h3>
          <div class="section-text">
            <p> {{ $t('sectionText1') }}</p>
            <p>{{ $t('sectionText2') }}</p>
            <p>{{ $t('sectionText3') }}</p>
          </div>

        </div>
        <div class="destinations-container">
          <Destination :refuge-img="stockImages[0]" link="/services/accommodation" :text="$t('Accommodation')"/>
          <Destination :refuge-img="stockImages[1]" link="/services/menu" :text="$t('Menu')"/>
          <Destination :refuge-img="stockImages[2]" link="/activities" :text="$t('Activities')"/>
        </div>

        <div class="full-width">
          <div class="information-section">
            <div class="information-left">
              <CldImage :modifiers="{ roundCorner: '10' }"
                        src="https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614474/refuge/outside/main_yjac0p.jpg"
                        alt="Far picture of the Refuge with a view of the valley below."
                        format="jpg" loading="lazy"
                        width="1000" height="1000" quality="70"/>
            </div>

            <div class="information-right">
              <h2>{{ $t('informationText') }}</h2>
              <div class="pills-container">
                <div class="pill">
                  <h3>{{ $t('builtIn') }}</h3>
                  <h4>1998</h4>
                </div>
                <div class="pill">
                  <h3>{{ $t('renovatedIn') }}</h3>
                  <h4>2022</h4>
                </div>
                <div class="pill">
                  <h3>{{ $t('altitude') }}</h3>
                  <h4>1665m</h4>
                </div>
              </div>

              <InternalRedirectButton redirect="/refuge/booking" :text="$t('bookingInformation')"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
$darker_background_color: rgb(25, 25, 25)
$dark_background_color: rgb(30, 30, 30)

$hover_dark_gray: rgb(40, 40, 40)
$light_gray: rgb(220, 220, 220)
$hover_light_gray: rgb(200, 200, 200)

.dark-mode .information-right a
  color: white

.section-text
  width: 80%
  text-align: center
  align-self: center

.information-right
  display: flex
  flex-direction: column
  height: 100%
  text-align: left
  justify-content: center
  align-items: center

  a
    color: black

  h2
    margin-top: 0

.information-left
  height: 100%

  img
    height: 100%
    object-fit: cover
    border-radius: 5px
    overflow: hidden

.information-section
  display: grid
  grid-template-columns: 30% 70%
  gap: 10px
  padding: 20px 0
  height: 500px
  width: 80%

.pills-container
  display: flex
  width: 100%
  justify-content: space-evenly
  margin: 2rem
  align-self: center

.dark-mode .pill
  background-color: $dark_background_color
  color: white

  &:hover
    background-color: $hover_dark_gray

.pill
  display: flex
  align-items: center
  flex-direction: column
  background-color: $light_gray
  width: auto
  height: fit-content
  padding: 2ch 3ch
  margin: 0
  border-radius: 30px
  gap: 1ch
  user-select: none
  transition: all 300ms ease-in-out

  &:hover
    background-color: $hover_light_gray

  h3, h4
    margin: 0

.destinations-container
  display: flex
  justify-content: space-around
  margin: 3rem 0
  min-width: 500px
  width: 50vw
  gap: 2ch
  position: relative
  padding-bottom: 20px

.flex-column
  display: flex
  flex-direction: column

.flex-row
  display: flex
  height: 100%

.section
  width: 60%

.main-content
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  text-align: center
  font-family: 'Montserrat', sans-serif
  margin-top: 30px

.section h1
  font-weight: 500
  margin-bottom: 1rem

.section h2
  font-weight: 500
  font-size: 1.5rem
  margin-bottom: 1rem
  margin-top: 0

.section p
  font-weight: normal
  text-align: justify

.dark-mode .full-width
  background-color: $darker_background_color

.full-width
  display: flex
  align-items: center
  justify-content: center
  height: fit-content
  width: 100vw
  background-color: #efefef

.banner-img
  display: flex
  width: 100vw
  height: 100%

.espa
  position: fixed
  bottom: 10px
  right: 1%
  width: 10%
  max-width: 10vw
  z-index: 10
  border-radius: 5px
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4)
  transition: all 300ms

  &:hover
    scale: 1.05

.banner-text
  font-size: 2.6rem
  color: rgba(255, 255, 255, 0.8)
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7)
  position: absolute
  top: 40%
  left: 50%
  transform: translate(-50%, -50%)

.banner
  padding: 0
  margin: 0
  position: relative
  width: 100vw
  max-height: 90vh
  height: 90vh
  overflow: hidden
  display: flex
  align-items: center

.home-container
  display: flex
  min-height: 100vh
  flex-direction: column
  justify-content: flex-start
  align-items: center
  text-align: center


@media (max-width: 1280px)
  .main-content
    width: 100vw

  .section-text
    width: 100%
  .section
    width: 90%

  .destinations-container
    width: 100%
    gap: 10px

  .information-section
    height: fit-content
    display: grid
    grid-template-columns: 20% 80%
    width: 100vw

    padding: 20px 0
    gap: 30px

  .information-left
    height: 100%
    border-radius: 0 10px 10px 0
    overflow: hidden

    img
      height: 100%
      object-fit: cover

  .information-right
    width: 80%

@media (max-width: 480px)
  .espa
    width: 20%

  .section
    width: 95%

    h1
      font-size: 1.5rem

    p
      padding: 5%
      margin: 0
      text-align: left

  .banner
    height: 70vh

    h1
      font-size: 1.5rem

    .banner-img
      object-fit: cover
      height: 2000px

    video
      object-fit: cover
      height: 100%

  .pills-container
    flex-direction: column
    gap: 5px
    margin: 0

  .pill
    border-radius: 5px

    h3
      font-size: 1.3rem
      font-weight: bold

    h4
      font-size: 1.2rem

  .information-section
    display: flex
    flex-direction: column
    width: 90vw
    height: fit-content
    padding: 20px 0
    gap: 10px

  .information-right
    width: 100%
    align-items: center
    gap: 10px

    p
      margin-top: 10px

  .information-left img
    width: 200px !important
    height: 200px
    border-radius: 50%

  .destinations-container
    flex-wrap: nowrap
    flex-direction: column
    align-items: center
    width: 95%
    margin: 0
    gap: 10px

</style>
